var chartPlugins = {
  legend: {
    position: 'top',
    align: 'start',
    reverse: false,
    labels: {
      color: "#fff",
      padding: 20,
      usePointStyle: true,
      pointStyleWidth: 17,
      pointStyleHeight: 17,
    }
  },
  tooltip: {
    callbacks: {
      label: function (tooltipItem, data) {
        if (tooltipItem.dataset.yAxisID == 'y1') {
          return tooltipItem.dataset.label + ":" + tooltipItem.formattedValue + '%';
        } else {
          return tooltipItem.yLabel;
        }
      }
    }
  },
}
var chartPlugins_reverse = {
  legend: {
    position: 'top',
    align: 'start',
    reverse: true,
    labels: {
      color: "#fff",
      padding: 20,
      usePointStyle: true,
      pointStyleWidth: 17,
      pointStyleHeight: 17,
    }
  },
  tooltip: {
    callbacks: {
      label: function (tooltipItem, data) {
        if (tooltipItem.dataset.yAxisID == 'y1') {
          return tooltipItem.dataset.label + ":" + tooltipItem.formattedValue + '%';
        } else {
          return tooltipItem.yLabel;
        }
      }
    }
  },
}
var chartPlugins_game = {
  legend: {
    position: 'top',
    align: 'start',
    labels: {
      color: "#fff",
      padding: 20,
      usePointStyle: true,
      pointStyleWidth: 17,
      pointStyleHeight: 17,
    }
  },
  tooltip: {
    callbacks: {
      label: function (tooltipItem, data) {
        return tooltipItem.dataset.label + ":" + tooltipItem.formattedValue + '%';
      }
    }
  },
}


var chartPluginsBefore = [{
  beforeInit(chart) {
    const origFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      origFit.bind(chart.legend)();
      this.height += 30;
    }
  },
}];

window.chartPlugins = chartPlugins;
window.chartPlugins_reverse = chartPlugins_reverse;
window.chartPlugins_game = chartPlugins_game;
window.chartPluginsBefore = chartPluginsBefore;
