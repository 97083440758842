import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination } from 'swiper';
import AOS from 'aos';
// import { CountUp } from 'countup.js';

Swiper.use([Navigation, Pagination]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init({
    once: true, // whether animation should happen only once - while
  });

  // scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // more buttons
  $("#btnMore").hover(function () {
    if (window.innerWidth > 1280) {
      let isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children('.more-links').stop().slideDown(300);
      } else {
        $(this).children('.more-links').stop().slideUp(300);
      }
    }
  });

  $("#btnMore").on('click', function () {
    if (window.innerWidth <= 1280) {
      if ($(this).hasClass('is-active')) {
        $(this).children('.more-links').stop().slideUp(300);
        $('#btnMore').removeClass('is-active');
      }else {
        $('#btnMore').addClass('is-active')
        $(this).children('.more-links').stop().slideDown(300);
      }
    }
  });

  $("#btnMore").focusout(function () {
    $(this).children('.more-links').stop().slideUp(300);
    $('#btnMore').removeClass('is-active');
  });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 70) {
      $('.header').addClass("min-header");
      $('.back-to-top').show();
    } else {
      $('.header').removeClass("min-header");
      $('.back-to-top').hide();
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $(".header-menu-link").hover(function () {
    if (window.innerWidth > 1024) {
      let isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children('.header-menu-dropdown').stop().slideDown(300);
        var dropdown_width = $(this).find('.header-menu-dropdown-inner').width();
        if (dropdown_width > 270) {
          $(this).find('.header-menu-dropdown').addClass('fixed-width');
        }
      } else {
        $(this).children('.header-menu-dropdown').stop().slideUp(300);
      }
    }
  });

  $('.dropdown .menu-link').click(function(e) {
    if (window.innerWidth  <= 1280) {
      e.preventDefault();
      if ($(this).parent('.header-menu-link').hasClass('active')) {
        $(this).siblings('.header-menu-dropdown').stop().slideUp(300);
        setTimeout(() => {
          $(this).parent('.header-menu-link').removeClass('active');
        }, 300);
      } else {
        $(this).parent('.header-menu-link').addClass('active');
        $(this).siblings('.header-menu-dropdown').stop().slideDown(300);
      }
    }
  });

  $('.dropdown-link .dropdown-link-inner').click(function(e) {
    if (window.innerWidth <= 1280) {
      e.preventDefault();
      if ($(this).parent('.dropdown-link').hasClass('active')) {
        $(this).siblings('.second-dropdown-links').slideUp(300);
        setTimeout(() => {
          $(this).parent('.dropdown-link').removeClass('active');
        }, 300);
      } else {
        $(this).parent('.dropdown-link').addClass('active');
        $(this).siblings('.second-dropdown-links').slideDown(300);
      }
    }
  });

  //form input
  document.querySelectorAll('input, select, textarea').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length >= 0) {
        input.classList.add('focused')
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      };
    });

    input.addEventListener('focus', () => {
      input.classList.add('focused')
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    });

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.classList.remove('focused')
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      };
    });
  });

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });


  // dropdown
  $('.dropdown-btn').on('click', function() {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id +']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height' : '',
          'overflow' : '',
        })
      }
    }else {
      $(".dropdown-list:not([aria-labelledby=" + id +"]").removeClass('is-active');
      $('[aria-labelledby=' + id +']').addClass('is-active');
      if(window.innerWidth <= 640) {
        $('body').css({
          'height' : '100vh',
          'overflow' : 'hidden',
        })
      }
    }
  });

  $(document).click(function(event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  $('.calendar-btn').on('click', function() {
    $(this).toggleClass('active').siblings('.calendar-dropdown').toggleClass('active');
    $(this).closest('.calendar-icon-group-inner').toggleClass('active');
    if(window.innerWidth <= 640) {
      $('body').css({
        'height' : '',
        'overflow' : '',
      })
    }
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.calendar-btn').length) {
      if($('.calendar-dropdown').is(":visible")) {
        $(".calendar-dropdown").removeClass('active').siblings('.calendar-btn').removeClass('active').closest('.calendar-icon-group-inner').removeClass('active');
      }
    }
  });

  // accordion
  var activeFaq = $('.accordion-item .accordion-main');
  $(activeFaq).click(function(){
    $(this).toggleClass('active').siblings('.accordion-content').slideToggle(200);
    $(this).parent('.accordion-item').siblings('.accordion-item').find('.accordion-content').slideUp(200).siblings('.accordion-main').removeClass('active');
  });

  var slide_tabs = new Swiper('#slide_tabs', {
    slidesPerView: 'auto',
    peed: 1000,
    navigation: {
      nextEl: '.slide-tab-next',
      prevEl: '.slide-tab-prev',
    },
  });

  if ($('#slide_tabs').length > 0) {
    var totalWidth = 0;
    $('.slide-tab').each(function (index) {
      totalWidth += $(this).width();
      return totalWidth;
    });
    if ($('#slide_tabs').width() > totalWidth + 20 ) {
      $('#slide_tabs .swiper-wrapper').css('justify-content', 'center')
    }
  }

  $('#search_btn').on('click', function() {
    $('#search_popup').addClass('is-active');
  })
  $('.search-popup-close, .search-popup-mask').on('click', function() {
    $('#search_popup').removeClass('is-active');
  })
});
