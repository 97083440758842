import 'public/js/sweetalert';
import Cookies from 'js-cookie';
import Chart from 'chart.js/auto';
import 'core-js';
import 'public/main'
import 'public/js/chart_options'
import 'public/style.scss'
import ahoy from "ahoy.js"
import 'select2';
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.Chart = Chart;

